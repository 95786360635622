.swiper {
	// height: 28vw; // 1 slide
	height: 18vw;

	@include breakpoint(ld) {
		height: 23vw;
	}

	@include breakpoint(md) {
		height: 40vw;
	}

	@include breakpoint(sm) {
		height: 70vw;
	}
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #232323;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	color: #fff;
}

.swiper-pagination-bullet-active {
	background-color: #fff;
}
