input,
textarea,
label,
select {
	width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='date']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input,
select,
textarea {
	padding: 4px;
	margin: 4px 4px 4px 0px;
	transition: 1s all;
	border: 1px solid #f4f5f6;
	border-radius: 5px;
	// background: #f9f9f9;
	color: #212529;

	@include breakpoint(md) {
		width: 100%;
		margin-bottom: 0px;

		input:last-of-type {
			margin-bottom: 0;
		}
	}
}

input {
	&:focus {
		background: #fff;
		border: 1px solid #75b1ff;
		box-shadow: 0 0 5px #75b1ff;

		&::placeholder {
			color: transparent;
		}
	}

	&:hover {
		border: 1px solid #eaeaea;
	}
}


.checkbox {
	input[type='checkbox'] {
		opacity: 0;
		display: none;
	}

	label {
		position: relative;
		display: inline-block;
		padding-left: 50px;
		font-size: 1.5em;
		margin-bottom: 0.5rem;

		@include breakpoint(sm) {
			padding-top: 5px;
		}

		&::before,
		&::after {
			position: absolute;
			content: '';
			display: inline-block;
		}

		&::before {
			height: 36px;
			width: 36px;
			border: 1px solid #f4f5f6;
			background: #f9f9f9;
			left: 0px;
			border-radius: 3px;
			top: 3px;
		}

		&::after {
			height: 8px;
			width: 20px;
			border-left: 2px solid;
			border-bottom: 2px solid;
			transform: rotate(-45deg);
			left: 8px;
			top: 14px;
		}
	}

	input[type='checkbox'] + label::after {
		content: none;
	}

	input[type='checkbox']:checked + label::after {
		content: '';
	}

	input[type='checkbox']:focus + label::before {
		outline: rgb(59, 153, 252) auto 2.5px;
	}
}

.buttons {
	width: 100%;
	text-align: center;
	padding: 25px;

	@include breakpoint(md) {
		padding: 10px;
	}

	button {
		padding: 20px 30px;
		min-height: 70px;
	}

	.button {
		padding: 20px 30px;
		display: inline-block;
		width: fit-content;

		@include breakpoint(md) {
			display: block;
			width: 100%;
		}
	}

	button,
	.button {
		margin: 4px;
		font-weight: 300;
		border-radius: 11px;
		border: 1px solid #dadada;
		@include fluid-type(16px, 19px);
		text-align: center;
		transition: background-color, 0.3s linear;
		transition: color, 0.3s ease;

		@include breakpoint(md) {
			margin: 2px;
			width: 100%;
		}

		i {
			padding-right: 7px;
		}
	}
}

// .tag,
// .button {
// 	// @include fluid-type(18px, 22px);
// 	// @include fluid-type(20px, 20px);
// }

.checkbox {
	padding: 14px 0;
}

input,
.filepond--drop-label,
.filepond--panel-root,
textarea,
select {
	background: #fbfbfb;
	border: 1px solid #f4f5f6;
	border-radius: 5px;
}

.filepond--list-scroller {
	top: 7.5px;
}

.errorlist {
	margin: 10px 0;
	font-weight: 400;
}

input,
select {
	font-weight: 500;
}

textarea {
	font-weight: 300;
}

label {
	font-weight: 400;
	text-transform: none;
}

.checkbox label {
	font-size: 24px;
	font-weight: 300;

	span {
		vertical-align: middle;
	}
}
