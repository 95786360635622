@import './dependencies.scss';
@import './tools/fluid-type.scss';
@import './tools/breakpoints.scss';
@import './tools/tags.scss';
@import './tools/colors.scss';
@import './tools/forms.scss';
@import './tools/swiper.scss';

* {
	margin: 0;
}

html,
body {
	height: 100%;
}

body {
	height: 100%;
	background-color: $main-bgcol-background;
	-webkit-font-smoothing: antialiased;
	font-smooth: antialiased;
	@include fluid-type(16px, 17px);
	// @include fluid-type(15px, 16px);
	font-family: 'Open Sans';
	font-weight: 300;
}

.wrapper {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto -142px;
}

.push {
	height: 142px;
}

.smaller {
	max-width: 880px;
}

.wider {
	max-width: 1410px;
}

p {
	margin: 1em 0;
	text-indent: 26px;
	text-align: justify;
	word-spacing: 2px;
	@include fluid-type(16px, 17px);
	font-family: 'Open Sans';
	font-weight: 300;
}

a {
	transition: color, 0.5s ease;
	text-decoration: none;
	color: $main-color-link;

	&:hover {
		text-decoration: none;
		color: $main-color-link-hover;
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin-bottom: 20px;
	padding-bottom: 15px;
	font-family: 'Open Sans';
	font-weight: 300;
	border-bottom: 1px solid #f4f5f6;
	text-transform: uppercase;
}

h1 {
	@include fluid-type(26px, 36px);
}

h2 {
	@include fluid-type(30px, 40px);
}

h3 {
	@include fluid-type(20px, 30px);
	text-transform: none;
	font-style: italic;
}

h4 {
	@include fluid-type(18px, 22px);
}

h5 {
	@include fluid-type(16px, 18px);
}

#show-filter {
	margin: 20px 0;
	padding: 25px 0;
	border-radius: 4px;
	border: 1px solid #f4f5f6;
	background-color: rgb(255, 255, 255);
	color: #313131;
	transition: all 0.5s ease;

	@include fluid-type(22px, 24px);

	&:hover {
		cursor: pointer;
		border: 1px solid #b8b8b8;
		background-color: #ffe374;
		color: rgb(31, 31, 31);
	}
}

#filter-results {
	h3 {
		display: inline-block;
	}
}

.main-menu,
.admin-menu {
	.navbar-brand {
		margin-right: 0;
	}

	.navbar-nav .nav-link {
		margin-right: 25px;
		font-size: 1rem;
		font-weight: 400;
		transition: border-bottom-color, 0.3s ease;
		transition: color, 0.5s ease;
		color: #d0d0d0;
		border-bottom: 0.2px solid #141414;

		@include breakpoint(md) {
			margin-right: 0;
			// padding: 15.8px 70px;
			margin-bottom: 0;
			font-size: 1rem;
			text-align: center;
		}

		&:not(.active):not(.user) {
			&:hover {
				color: #e7d638 !important;
				border-bottom: 0.2px solid #e7d638;
			}
		}

		&.active {
			border-bottom: 0.2px solid #ffffff;
			font-weight: 600;
		}
	}
}

.menu {
	background: #141414;

	@include breakpoint(md) {
		padding: 25px 60px;
		text-align: center;
	}

	.nav-item {
		border-bottom: 0.2px solid #1b1b1b;

		&:first-of-type {
			@include breakpoint(md) {
				padding-top: 10px;
				margin-top: 30px;
				border-top: 0.5px solid #212121;
			}
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	.navbar-brand {
		margin-left: 25px;
		text-transform: uppercase;
		font-size: 1rem;
		font-weight: 400;

		@include breakpoint(md) {
			margin-top: 25px;
			margin-left: 0;
			font-size: 0.9rem;
		}
	}

	#owner {
		text-transform: none;
	}
}

.admin-menu {
	background: #1f1f1f;

	// @include breakpoint(md) {
	// 	padding: 10px 60px;
	// }

	.nav-link {
		font-size: 1rem !important;

		@include breakpoint(md) {
			border-top: 0.5px solid #353535;
			padding: 15.8px 0px;
		}
	}

	.nav-item {
		&:first-of-type {
			padding-top: 0px;
			border-top: none;
		}
	}
}

.start,
.about,
.contact {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 100px;
	color: #fff;

	@include breakpoint(md) {
		padding: 30px 0;
	}
}

.start {
	min-height: 100vh;
	background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('../img/zizkov.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.container {
		padding: 0 60px;
	}

	.filter {
		label {
			font-weight: 600;
			text-transform: uppercase;
		}

		.option {
			display: flex;
			justify-content: center;
			flex-direction: column;
			height: 140px;
			margin: 3px 2px;
			background-color: hsla(0, 0%, 3%, 0.2);
			color: #f4f5f6;
			text-shadow: 0.5px 0.5px 0.5px #525252;
			text-transform: uppercase;
			font-weight: 600;
			transition: color, 1.2s ease-out;
			transition: background-color 0.25s ease;

			@include breakpoint(sm) {
				height: 75px;
			}

			&.inactive {
				color: #b7b7b7;

				&:hover {
					background-color: rgba(112, 112, 112, 0.45);
				}

				input[type='checkbox']:checked + label {
					color: #b7b7b7;
				}
			}

			&:hover:not(.inactive) {
				background-color: hsla(0, 0%, 46%, 0.85);
				color: $main-color-link;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}

			input[type='checkbox']:checked + label {
				color: $main-color-link;
			}

			input[type='checkbox'] {
				display: none;
			}

			label {
				height: 140px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				cursor: pointer;
			}

			i {
				display: block;
				font-size: 3em;
				margin: 5px;

				@include breakpoint(sm) {
					font-size: 1.9em;
				}
			}
		}
	}

	.buttons {
		margin-top: 20px;
		text-align: center;

		button,
		.button {
			padding: 10px 70px;
			border: none;
			font-weight: 600;
			background-color: rgba(255, 255, 255, 0.3);
			transition: background-color 0.5s ease;

			@include breakpoint(sm) {
				padding: 10px 25px;
			}

			&:hover {
				background-color: rgba(138, 208, 167, 1);
				cursor: pointer;
			}

			a {
				color: white;
			}
		}
	}
}

.about,
.contact {
	.container {
		p {
			&:first-child {
				@include fluid-type(22px, 26px);
				word-spacing: 1px;
				border-bottom: 1px solid #ffffff1a;
				padding-bottom: 20px;
				text-indent: 0;
				text-align: center;
			}
		}

		p {
			@include fluid-type(17px, 19px);
		}
	}
}

.about {
	min-height: 62vh;
	background: linear-gradient(#000, rgba(0, 0, 0, 0.72)), url(../img/kancelar.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-position-y: 0%, 48%;
	padding-bottom: 0;

	.container {
		padding: 0 8vw;
	}

	@include breakpoint(md) {
		padding-bottom: 30px;
	}
}

.copyright {
	padding: 90px 0;
	background-color: black;
	color: #c7c7c7;

	.year {
		@include breakpoint(sm) {
			display: block;
		}
	}

	ul {
		list-style: none;
		text-align: center;
		margin-bottom: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-right: 50px;
			text-transform: uppercase;
			padding-bottom: 0;

			a {
				color: #c7c7c7;
				font-weight: 500;

				&:hover {
					color: $main-color-link;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}

			@include breakpoint(sm) {
				display: block;
				margin-right: 0;
				padding-bottom: 5px;
			}
		}
	}

	p {
		margin: 0;
		text-indent: 0;
		font-weight: 400;
	}

	.plug {
		padding: 6px;
		font-size: 13.6px;

		a {
			color: #717171;
		}

		a:not(:last-of-type) {
			margin-right: 24px;
		}
	}
}

.comp {
	padding: 60px 0;
}

#settings {
	background-color: $main-bgcol-background;
	border: none;

	.container-fluid {
		padding: 0;
	}

	#setting-knobs {
		background-color: white;
		border: 1px solid #f4f5f6;
		display: none;
		margin-bottom: 20px;
	}

	#filter-location {
		padding-top: 25px;
		background-color: white;

		.row {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			@include breakpoint(md) {
				margin-bottom: 10px;
			}
		}

		select,
		input {
			width: 220px;
			padding: 10px 10px;
			background: #fbfbfb;
		}
	}

	ul {
		padding: 0;

		li {
			list-style-type: none;
		}
	}

	.name {
		text-align: right;

		@include breakpoint(md) {
			text-align: left;
		}
	}

	.row {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@include breakpoint(md) {
		.name {
			border-right: none;
		}
	}
}

.output {
	.filter-results {
		margin-bottom: 20px;
	}

	.container {
		margin-bottom: 20px;
		background-color: #fff;
		border: 1px solid #f4f5f6;
		padding: 25px;
	}

	#filter-data-location {
		.amount,
		.params {
			border-bottom: none;
		}

		.amount {
			text-align: left;

			@include breakpoint(md) {
				border-bottom: 1px solid #e4e4e4;
				margin-bottom: 18px;
				padding-bottom: 20px;
			}
		}

		.params {
			text-align: right;
			float: right;

			@include breakpoint(md) {
				float: none;
			}
		}

		@include breakpoint(md) {
			text-align: center;
		}
	}

	.instance {
		margin: 25px 0;
		padding-bottom: 0px;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		header {
			background: #373737;
			border-bottom: none;
			padding: 0px;
		}

		.preview-image {
			height: 280px;
			width: 300px;
			position: relative;

			&:not(:first-child) {
				.img {
					margin-left: 2px;
				}
			}

			@include breakpoint(md) {
				width: 100%;
				height: 350px;

				&:not(:first-child) {
					display: none;
				}
			}

			.img {
				background-size: cover !important;
				background-repeat: no-repeat !important;
				background-position: center !important;
				background-color: #000000 !important;

				max-height: 100%;
				max-width: 100%;
				width: auto;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				@include breakpoint(md) {
					background-size: contain !important;
				}

				@include breakpoint(xs) {
					background-size: cover !important;
					width: 100%;
				}
			}
		}

		h2 {
			@include fluid-type(22px, 24px);
		}

		section {
			padding: 20px;
			font-weight: 300;
			color: #313131;
			background-color: $main-bgcol-background;
			border: 1px solid #f4f5f6;

			a {
				color: #313131;

				&:hover {
					color: $main-color-link-hover;
				}
			}
		}

		strong {
			font-weight: 500;
		}

		.regular {
			font-weight: 400;
		}

		.status {
			color: #969696;
			font-style: italic;
			font-size: 17px;
		}

		p {
			text-indent: 0;
			margin: 0;
			@include fluid-type(17px, 19px);

			&:first-of-type {
				margin-top: 5px;
			}

			@include breakpoint(md) {
				text-align: center;
			}
		}

		h4 {
			font-weight: 400;
			margin-bottom: 10px;
			padding-bottom: 10px;
		}

		h2 {
			border-bottom: none;
			margin-bottom: 0;

			@include breakpoint(md) {
				text-align: center;
			}
		}

		.row {
			margin: 0;
		}
	}
}

.services {
	h3 {
		text-align: left;

		&:first-of-type {
			@include breakpoint(sm) {
				text-align: center;
			}
		}
	}

	ul {
		list-style-type: none;
		padding: 10px;
		margin: 1em 0;
		border: 1px solid #f4f5f6;
		background-color: #fff;

		li {
			padding: 20px 20px;
			text-align: center;
			font-weight: 400;
			border-bottom: 1px solid #f4f5f6;

			i {
				margin-right: 10px;
			}

			a {
				color: #585858;

				&:hover {
					color: $main-color-link;
				}
			}

			p {
				text-align: center;
				text-indent: 0;
			}

			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}

.contact {
	min-height: 62vh;
	background: linear-gradient(#000, rgba(0, 0, 0, 0.72)), url(../img/stitneho.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-position-y: 0%, 48%;
	padding: 0;
}

.contact-list {
	.container-fluid {
		padding: 20px;
		background: rgba(20, 20, 20, 0.93);
		color: #b8b8b8;
		font-weight: 300;
	}

	ul {
		list-style-type: none;
		margin: 25px 0;
		padding: 0;

		li {
			text-align: center;
			@include fluid-type(20px, 30px);

			i {
				margin-right: 10px;
			}

			a {
				color: #b8b8b8;

				&:hover {
					color: $main-color-link;
				}
			}
		}
	}
}

.gmap {
	.container-fluid {
		padding: 0;
	}
}

.detail {
	header {
		a {
			color: rgb(0, 0, 0);
			transition: 1s all;

			&:hover {
				color: rgb(250, 214, 9);
				cursor: pointer;
			}
		}

		&:first-of-type {
			background: white;
			padding: 30px;
			border: 1px solid #f4f5f6;

			h3 {
				@include fluid-type(25px, 30px);
			}

			h4 {
				@include fluid-type(17px, 20px);
			}
		}
	}

	header h3 {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.row {
		border: none !important;
	}

	.map {
		padding: 0;
		border: 1px solid #f4f5f6;

		@include breakpoint(md) {
			height: 480px;
		}

		@include breakpoint(xs) {
			border-bottom: 1px #f4f5f6;
		}
	}

	.detail-parameters {
		background: #ffffff;
		border: 1px solid #f4f5f6 !important;
		border-left: none !important;
		padding: 30px 20px;

		hr {
			// margin: 2px 0 10px 0;
			margin: 10px 0 15px 0;
			color: #d0d0d0;
			@include breakpoint(sm) {
				display: none;
			}
		}

		.name {
			margin-bottom: 5px;
			text-align: left;
			font-weight: 300;
		}

		.value {
			font-weight: 500;
			text-align: left;
		}

		@include breakpoint(md) {
			border-right: 1px solid #f4f5f6 !important;
			border-left: 1px solid #f4f5f6 !important;
			border-bottom: none !important;

			.name,
			.value {
				text-align: center;

				&:last-of-type {
					border-bottom: none;
				}
			}

			.value {
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}

		@include breakpoint(sm) {
			padding: 30px 10px 20px 10px;
			text-align: center;
			border-bottom: none !important;

			.value {
				font-weight: 500;
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 0.5px solid #f4f5f6;

				&:last-child {
					margin-bottom: 0px;
					padding-bottom: 0px;
					border-bottom: none;
				}
			}
		}
	}

	.extra-top-pad {
		padding: 60px 10px !important;

		@include breakpoint(md) {
			padding: 30px 10px !important;
			margin-bottom: 15px;
		}
	}

	.image-gallery {
		margin: 0;
		background-color: #1c1c1c;
		padding: 0;
		font-weight: 300;
		color: #585858;

		.container {
			padding: 10px 0 5px 0;
			text-align: center;
			padding-right: 0;
			padding-left: 0;

			.manipulate {
				position: relative;
				display: inline-block;

				img {
					display: block;
					width: auto;
					height: 185px;
					margin: 5px 2.5px;
				}

				i {
					position: absolute;
					bottom: 10px;
					left: 10px;
					color: rgba(255, 231, 95, 0.9);
					text-shadow: 1px 1px 0.25px black;
					@include fluid-type(28px, 34px);
					transition: 0.5s all;

					&:hover {
						color: rgb(250, 214, 9);
						cursor: pointer;
					}
				}
			}

			div {
				.image {
					display: block;
				}
			}
		}
	}

	.details {
		.description {
			padding: 35px 70px;
			background: #fff;
			border: 1px solid #f4f5f6;
			margin-bottom: 0;
			margin-right: -12px;
			margin-left: -12px;

			&:first-of-type {
				border-top: none;
				border-bottom: none;
			}

			@include breakpoint(sm) {
				padding: 35px 40px;
			}
		}

		section {
			margin: 0 0 60px 0;

			p {
				line-height: 1.8em;

				/* For links inside exported text */
				white-space: pre-wrap; /* CSS3 */    
				white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
				white-space: -pre-wrap; /* Opera 4-6 */    
				white-space: -o-pre-wrap; /* Opera 7 */    
				word-wrap: break-word; /* Internet Explorer 5.5+ */
			}

			.row:first-of-type {
				background: #fff;
				border: 1px solid #f4f5f6;
				font-weight: 500;
			}

			.col-md {
				margin: 3px;
				text-align: left;

				@include breakpoint(sm) {
					text-align: center;
				}

				.name {
					color: #888888;
					margin: 2.5px 0;
				}

				.value {
					@include breakpoint(sm) {
						margin-bottom: 10px;

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}

				h4 {
					font-weight: 400;
				}
			}

			.important {
				margin-bottom: 10px;
				padding-bottom: 10px;
				color: black !important;

				@include breakpoint(md) {
				}
			}
		}
	}
}

.admin-panel {
	.buttons {
		margin-bottom: 20px;
	}

	.new-property,
	.new-user {
		padding: 45px;
		border: 1px solid #f4f5f6;
		background-color: #fff;
	}

	.new-user {
		.buttons {
			margin-top: 20px;
		}
	}

	.flat_section,
	.house_section,
	.comm_section,
	.build_section,
	.user_section {
		.table-responsive {
			border: 1px solid $main-border;
		}
	}

	.option-filter,
	.table-labels {
		border-left: 0.6px solid #aaaaaa;
		border-right: 0.6px solid #aaaaaa;
	}

	.table-labels {
		border-top: 0;
		border-bottom: 0.6px solid #aaaaaa;
	}

	.option-filter {
		th {
			background-color: #a6adaf;
		}

		input,
		select {
			width: 100%;
			text-align: center;
		}
	}

	.flat_section,
	.house_section,
	.comm_section,
	.build_section,
	.user_section {
		form,
		button,
		.button {
			padding: 0;
		}

		.link-button {
			color: #585858;
			font-weight: 500;
			background: $main-bgcol-foreground;
			border: none !important;
			cursor: pointer;
			transition: color, 0.5s ease;
		}

		.link-button:focus {
			outline: none;
		}

		.link-button:hover {
			color: #e7d638;
		}
	}

	.table-labels {
		background-color: #a6adaf;
		color: #ffffff;

		th {
			transition: color, 0.5s ease;
			border-bottom: 1px solid #9a9a9a;

			&:hover {
				color: #f5e76d;
				cursor: pointer;
			}
		}
	}

	h4 {
		@include breakpoint(md) {
			margin-top: 30px;
		}
	}

	.navigation {
		background: $main-bgcol-foreground;
		border: 1px solid $main-border;
		color: #585858;
		margin-bottom: 25px;
	}

	.container {
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	table {
		padding: 15px;
		background: $main-bgcol-foreground;
		color: #585858;
		text-align: center;
		font-weight: 300;
		margin-bottom: 0;
		@include fluid-type(14px, 17px);

		@include breakpoint(md) {
			margin-bottom: 0;
		}

		a {
			font-weight: 500;
			color: #585858;

			&:hover {
				color: #beab06;
			}
		}

		th,
		td {
			padding: 0.45rem;
			vertical-align: top;
			border: 0;
			border-bottom-width: 0;
		}

		th {
			border-bottom: none;
			font-weight: 550;
			text-transform: uppercase;
		}

		tbody {
			border-top: 1.5px solid #707070 !important;

			tr {
				transition: background-color 0.3s linear;
			}

			tr:hover {
				.link-button {
					background-color: #e2e2e2;
				}

				background-color: #e2e2e2;
			}
		}

		.inactive_flat {
			color: black;
			background: #c8c8c8;

			&:hover {
				.link-button {
					background-color: #e2e2e2 !important;
				}
			}

			.link-button {
				background: #c8c8c8 !important;
				color: black;

				&:hover {
					background: #e2e2e2 !important;
				}
			}
		}
	}

	.add-form {
		.col-md-6:first-of-type form,
		.col-md-12 form {
			width: 300px;
			margin: 0 auto;
		}

		textarea {
			min-height: 280px;
		}

		.buttons {
			padding: 0;
			margin-bottom: 0;

			button,
			.button {
				margin: 0;
				width: 100%;
			}
		}
	}
}

.login {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 120px;

	.container {
		background-color: #fff;
		border-color: 1px solid #b5b5b5;
		padding: 80px;
	}

	.login-form {
		margin: 0 auto;
		padding-top: 20px;
		width: 300px;

		input {
			width: 100%;
			margin-bottom: 10px;
		}

		.buttons {
			padding-bottom: 0;

			button,
			.button {
				margin: 0;
				width: 100%;
			}
		}
	}
}

.property-edit {
	header:first-of-type {
		select {
			width: auto;
			height: auto;
			font-weight: 300;
			font-style: italic;
		}
	}

	textarea {
		width: 100%;
		padding: 10px 15px;
		margin: 0;
		min-height: 500px;
	}

	.detail-parameters {
		border-left: 1px solid #f4f5f6 !important;

		.name {
			line-height: 35px;
		}
	}

	input:not([type='file']),
	select {
		height: 35px;
		width: 100%;
	}
}

.filepond--credits {
	display: none !important;
}

.filepond--item {
	width: calc(50% - 0.5em);
}

@media (min-width: 30em) {
	.filepond--item {
		width: calc(50% - 0.5em);
	}
}

@media (min-width: 50em) {
	.filepond--item {
		width: calc(33.33% - 0.5em);
	}
}
