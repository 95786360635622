@import './fluid-type.scss';
@import './breakpoints.scss';
@import './colors.scss';

.hidden {
	opacity: 0;
}

.visible {
	opacity: 1;
}

.destroy {
	display: none;
}

.create {
	display: contents;
}

::-moz-selection {
	background: #75b1ff;
	color: #ffffff;
}

::selection {
	background: #75b1ff;
	color: #ffffff;
}

// buttons
.green,
.red,
.blue {
	color: white;

	&:hover {
		color: #3a3a3a;
	}
}

.green {
	background: $main-color-link;

	&:hover {
		background: rgba(var($main-color-link), 0.8);
	}
}

.red {
	background: #d0314b;

	&:hover {
		background: #eb314d;
	}
}

.blue {
	background: #64b0d0;

	&:hover {
		background: #67cceb;
	}
}

.black {
	background: #adadad;
	color: white;

	&:hover {
		background: #ababab;
		color: #3a3a3a;
	}

	a {
		color: white;

		&:hover {
			color: #3a3a3a;
		}
	}
}

.yellow {
	background: $main-color-button;
	color: $main-color-button-text;

	&:hover {
		background: #ffdc2370;
		color: #333333;
	}

	a {
		color: white;

		&:hover {
			color: #3a3a3a;
		}
	}
}

// block element properties
.bigger {
	padding: 20px 40px;

	@include breakpoint(md) {
		padding: 8px 30px;
	}
}

.no-indent {
	text-indent: 0 !important;
}

.no-border {
	border-bottom: none !important;
}

.no-top-pad {
	padding-top: 0 !important;
}

.no-top-mar {
	margin-top: 0 !important;
}

.no-bot-pad {
	padding-bottom: 0 !important;
}

.no-bot-mar {
	margin-bottom: 0 !important;
}

.no-pad {
	padding: 0;
}

.no-mar {
	margin: 0;
}

.no-transform {
	text-transform: none;
}

.left-pad {
	padding-left: 10px;
}

.right-pad {
	padding-right: 10px;
}

.bottom-spaced {
	padding-bottom: 30px;
	margin-bottom: 40px;
	text-align: center;
}

// font properties
.float-right-text {
	float: right;
}

.float-left-text {
	float: left;
}

.float-right-text,
.float-left-text {
	@include breakpoint(md) {
		float: none;
		display: block;
		margin-top: 3px;
	}
}

.bigger {
	@include fluid-type(17px, 18px);
}

.lowercase {
	text-transform: lowercase;
}

.uppercase {
	text-transform: uppercase;
}

.light {
	font-weight: 300;
}

.bold {
	font-weight: 400;
}

.italic {
	font-style: italic;
}

.non-italic {
	font-style: normal;
}

.highlight {
	color: $main-color-link;
	font-weight: 500;
}

.error {
	color: $error-color;
	font-weight: 500;
}

.auto-width {
	@include breakpoint(md) {
		width: auto;
	}
}

// notifications
.toast-success,
.toast-warning,
.toast-error {
	@include fluid-type(14px, 16px);
	font-family: 'Open Sans';
	font-weight: 500;
}

.toast-success {
	background: rgb(112, 190, 112);
}

.toast-warning {
	background: rgb(230, 195, 99);
}

.toast-error {
	background: rgb(230, 119, 99);
}
