$main-color-link: #e7d638;
$main-color-link-hover: #eee381;

$main-color-button: #ffe975;
$main-color-button-text: #282828;
$main-color-button-hover: #ababab;
$main-color-button-text-hover: #3a3a3a;

$main-border: #f4f5f6;

// $main-bgcol-background: #fcfcfc;
$main-bgcol-background: #fbfbfb;
$main-bgcol-foreground: #fff;

$error-color: #ff8873;