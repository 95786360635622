@mixin breakpoint($point) {
	@if $point==xs {
		@media (max-width: 544px) {
			@content;
		}
	}

	@if $point==sm {
		@media (max-width: 768px) {
			@content;
		}
	}

	@if $point==md {
		@media (max-width: 992px) {
			@content;
		}
	}

	@if $point==ld {
		@media (max-width: 1600px) {
			@content;
		}
	}

	@if $point==xl {
		@media (min-width: 1601px) {
			@content;
		}
	}
}

$breakpoints: ('xl', 'ld', 'md', 'sm', 'xs');
